import React from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import "../GitGraphs.css";
import { AccContext } from "../Context/OtherContext";
import axios from "../axios";

function TradingDays() {
  const [info, setInfo] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [val, setVal] = React.useState("");
  const { values, values5 } = React.useContext(AccContext);
  const [showlogin] = values;
  const [showSca] = values5;
  React.useEffect(() => {
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    if (showSca !== "") {
      axios
        .post("/gettradingdays/", { number: showSca, device_type: deviceType })
        .then((res) => {
          console.log(res.data);
          setInfo(
            res.data.map((item) => {
              return { date: item.date };
            })
          );
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      axios
        .post("/gettradingdays/", {
          number: showlogin,
          device_type: deviceType,
        })
        .then((res) => {
          console.log(res.data);
          setInfo(
            res.data.map((item) => {
              return { date: item.date };
            })
          );
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, [showlogin, showSca]);

  let handleClick = (value) => {
    if (value === null) {
      setOpen2(true);
      setVal("no trading record for this day");
    } else {
      setOpen(true);
      setVal(value);
      //console.log(value);
    }
  };

  return (
    <div style={{ margin: "7% 0% 0% 0%" }}>
      <dialog
        onClick={() => {
          setOpen(false);
        }}
        open={open}
      >
        <div>
          Traded on this day {val.date}
          <br />
          <br />
          <span className="cursor-pointer">Click to close</span>
        </div>
      </dialog>
      <dialog
        onClick={() => {
          setOpen2(false);
        }}
        open={open2}
      >
        <div>
          No trading record for this day
          <br />
          <br />
          <span className="cursor-pointer">Click to close</span>
        </div>
      </dialog>
      <CalendarHeatmap
        startDate={new Date("2024-07-01")}
        endDate={new Date("2025-06-30")}
        values={info}
        onClick={handleClick}
        showWeekdayLabels={true}
      />
    </div>
  );
}

export default TradingDays;
